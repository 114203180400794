<template>

    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>External contacts</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\ External contacts
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <div class="card-toolbar">
                    <v-btn  @click="createOrUpdateAddressBook()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add new
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" sm="12" :md="currentUser.access_type == 'score' ? '6' : '5'">
                    <v-text-field label="Search by name, UID, email, phone, or occupation"  v-on:keyup.enter="searchAddressBook"  clearable v-model="search.info" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" :md="currentUser.access_type == 'score' ? '3' : '2'" v-if="currentUser.access_type!='score'">
                    <v-select
                        :items="scores" v-on:keyup.enter="searchAddressBook"
                        v-model="search.score_id"
                        label="SCORE"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense clearable
                    ></v-select>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="12" :md="currentUser.access_type == 'score' ? '3' : '2'">
                    <v-select v-on:keyup.enter="searchAddressBook"
                              :items="status"
                              v-model="search.is_active"
                              label="Status"
                              item-text="name"
                              item-value="value"
                              outlined
                              dense clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" :md="currentUser.access_type == 'score' ? '3' : '3'" class="text-right">
                    <v-btn :loading="isLoading"
                           @click="searchAddressBook"
                           class="btn btn-primary btn-search"
                           style="color: #fff;"
                    >
                      <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div class="table-responsive" style="table-layout: fixed">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="isLoading">
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="isLoading"
                    type="table-row-divider@25">
                </v-skeleton-loader>
                <table class="table" v-if="!isLoading">
                  <thead>
                  <tr class="px-3">
                    <th class="px-3" style="max-width: 150px !important; white-space: pre-wrap;">First name</th>
                    <th class="px-3" style="max-width: 150px !important; white-space: pre-wrap;">Surname</th>
                    <th class="px-3">Email</th>
                    <th class="px-3">Phone</th>
                    <th class="px-3">Occupation</th>
                    <th class="px-3" style="max-width: 150px !important; white-space: pre-wrap;">SCORE</th>
                    <th class="px-3">Status</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template>
                    <tr v-if="addressBooks.length > 0" v-for="item in addressBooks">
                      <td style="max-width: 150px !important; white-space: pre-wrap;" class="px-3">
                        <span class="font-weight-bolder mb-1">
                          {{item.first_name }}
                        </span>
                      </td>
                      <td style="max-width: 150px !important; white-space: pre-wrap;" class="px-3">
                        <a @click="createOrUpdateAddressBook(item.id)" class="text-dark-75 text-hover-primary mb-1">
                          {{item.last_name ? item.last_name : 'NA' }}
                        </a>
                      </td>
                      <td class="px-3">
                        <a  class="text-info font-weight-bolder text-hover-primary mb-1 font-size-lg" :href="'mailto:'+item.email">
                          {{item.email ? item.email : 'NA' }}
                        </a>
                      </td>
                      <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{item.phone_format}}
                        </span>
                      </td>
                      <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{item.occupation ? item.occupation : '-'}}
                        </span>
                      </td>

                      <td style="max-width: 150px !important; white-space: pre-wrap;" class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{item.score_name}}
                        </span>
                      </td>

                      <td class="px-3">
                          <span class="badge"
                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                          >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="createOrUpdateAddressBook(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-edit"></i>
                                </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item">
                                <a  @click="deleteAddressBook(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-trash"></i>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="addressBooks.length == 0">
                      <td colspan="7" class="text-center">
                        <strong>No items added</strong>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <b-pagination
                    v-if="addressBooks.length > 0"
                    class="pull-right mt-7"
                    @input="getAllAddressBook"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app>
</template>
<script>
import AddressBookService from "@/services/address-book/AddressBookService";
import ScoreService from "@/services/score/score/ScoreService";

const score = new ScoreService();
const addressBook = new AddressBookService;
export default {
  data(){
    return{
      isLoading:false,
      search:{
        info:'',
        is_active:'',
        score_id:'',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      total: null,
      perPage: null,
      page: null,
      scores:[],
      addressBooks:[],
      currentUser:{},
    }
  },
  methods:{
    getAllAddressBook(){
      this.isLoading=true;
      addressBook
          .paginate(this.search,this.page)
          .then(response => {
            this.addressBooks=response.data.data;
            this.isLoading=false;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          });
    },
    createOrUpdateAddressBook(addressBookId){

      if(addressBookId==null || addressBookId==undefined)
        this.$router.push({
          name:"address-book-create",
        });
      else
        this.$router.push({
          name:'address-book-edit',
          params:{addressBookId:addressBookId}
        })
    },
    searchAddressBook(){
      this.getAllAddressBook();
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    deleteAddressBook(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            addressBook
                .delete(item)
                .then((response) => {
                  this.getAllAddressBook();
                  this.$snotify.success("External contact deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },
  mounted() {
    this.getCurrentUser();
    this.getAllAddressBook();
    this.getAllScore();
  }
}
</script>